import React from "react"
import CoreProviders from "./punks/core"
import PrismicRootProviders from "./punks/prismic"
import { MuiProviders } from "@punks/ui-mui"

interface Params {
  element: any
}

export const wrapRootElement = ({ element }: Params) => {
  return (
    <CoreProviders>
      <PrismicRootProviders>
        <MuiProviders>{element}</MuiProviders>
      </PrismicRootProviders>
    </CoreProviders>
  )
}

// export * from "@punks/contents-prismic/fragments"
