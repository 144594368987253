import React from "react"
import { PrismicWebsiteSettingsProvider } from "@punks/contents-prismic"
import { graphql, useStaticQuery } from "gatsby"

export const usePrismicSettingsQuery = () =>
  useStaticQuery(graphql`
    query WebsiteSettings {
      allPrismicWebsiteSettings {
        nodes {
          lang
          data {
            website_name
            home_page {
              id
            }
            is_fallback_language
          }
        }
      }
    }
  `)

export const SiteSettingsProvider = ({ children }: any) => {
  const settings = usePrismicSettingsQuery()
  return (
    <PrismicWebsiteSettingsProvider value={settings}>
      {children}
    </PrismicWebsiteSettingsProvider>
  )
}
