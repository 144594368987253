import ReactDOM from "react-dom"
import "./src/styles/reset.css"

export function replaceHydrateFunction() {
  return (element, container, callback) => {
    ReactDOM.render(element, container, callback)
  }
}

export const onServiceWorkerUpdateReady = () => {
  window.location.reload()
}

export const registerServiceWorker = () => true

export { wrapRootElement } from "./src/wrapRoot"
