import React from "react"
import { RootProviders } from "@punks/core"
import { graphql, useStaticQuery } from "gatsby"

interface Props {
  children: any
}

const CoreProviders = ({ children }: Props) => {
  const { allSitePage, site } = useStaticQuery(graphql`
    query SiteData {
      allSitePage {
        nodes {
          path
        }
      }
      site {
        siteMetadata {
          title
          description
        }
      }
    }
  `)

  return (
    <RootProviders pages={allSitePage} metadata={site.siteMetadata}>
      {children}
    </RootProviders>
  )
}

export default CoreProviders
