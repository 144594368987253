import React from "react"
import { PrismicRepoSettingsProvider } from "@punks/contents-prismic"
import { graphql, useStaticQuery } from "gatsby"

export const usePluginQuery = () =>
  useStaticQuery(graphql`
    query PrismicSourcePlugin {
      sitePlugin(name: { eq: "gatsby-source-prismic" }) {
        pluginOptions {
          repositoryName
        }
      }
    }
  `)

export const RepoSettingsProvider = ({ children }: any) => {
  const { sitePlugin } = usePluginQuery()
  return (
    <PrismicRepoSettingsProvider
      repositoryName={sitePlugin.pluginOptions.repositoryName}
    >
      {children}
    </PrismicRepoSettingsProvider>
  )
}
