import React from "react"
import { PrismicCommonProviders } from "@punks/contents-prismic"
import { RepoSettingsProvider } from "./RepoSettingsProvider"
import { SiteThemeProvider } from "./SiteThemeProvider"
import { SiteSettingsProvider } from "./SiteSettingsProvider"

const PrismicRootProviders = ({ children }: any) => {
  return (
    <PrismicCommonProviders>
      <RepoSettingsProvider>
        <SiteSettingsProvider>
          <SiteThemeProvider>{children}</SiteThemeProvider>
        </SiteSettingsProvider>
      </RepoSettingsProvider>
    </PrismicCommonProviders>
  )
}

export default PrismicRootProviders
